<template>
  <div class='payment-callback__default-template'>
    <div class="container">
      <blockCard>
        <h2 class='text-orange mb-16 font-medium text-center sm:text-left'>{{ $t('orderNotCompleted') }}</h2>

        <div class="notice">
          <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
          <span class='text-sm text-grey-darkest'>{{ $t('waitingForPaid`') }}</span>
        </div>
      </blockCard>

      <div class="btn-group flex mt-16 sm:mt-40 mb-40 justify-center sm:justify-start">
        <button class='btn btn-solid btn-blue mr-16'>{{ $t('repay') }}</button>
        <button class='btn btn-ghost btn-blue'>{{ $t('cancelPay') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import blockCard from '@/components/blockCard'

export default {
  name: 'payment-callback-bank',
  components: {
    blockCard
  }
}
</script>

<style lang='sass' scoped>
.payment-callback__default-template
  margin-bottom: 150px
</style>
