<template>
  <div class='payment-callback__qrcode-template'>
    <div class="container">
      <blockCard>
        <h2 class='text-orange mb-16 font-medium text-center sm:text-left'>{{ $t('orderNotCompleted') }}</h2>

        <div class="notice flex items-center mb-24">
          <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
          <span>{{ $t('qrCodeTip') }}
            {{ $t(qrcodeFor) }}
            {{ $t('continueToPay') }}</span>
        </div>
        <a
          class=" block qr-code m-auto sm:ml-0"
          :href="isTouchDevice ? qrcodeUrl : '#'">
          <img class="w-full" :src="qrcodeImg" alt="">
        </a>
      </blockCard>
      <div class="btn-group flex flex-col sm:block mt-16 sm:mt-40">
        <a
          v-if="isTouchDevice"
          :href="qrcodeUrl"
          class='btn btn-solid btn-line w-full mb-16 sm:hidden text-center no-underline'>{{ $t(qrcodeFor) }}</a>
        <button
          class='btn btn-solid btn-blue w-full sm:w-auto'
          @click='goHome'>{{ $t('backHome') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import blockCard from '@/components/blockCard'
import { mapActions, mapGetters } from 'vuex'
// import { clearInterval } from 'timers'
export default {
  name: 'line-template',
  props: {
    qrcodeImg: {
      type: String,
      required: true
    },
    qrcodeFor: {
      type: String,
      required: true
    },
    qrcodeUrl: {
      type: String,
      required: true,
      default: '#'
    }
  },
  components: {
    blockCard
  },
  computed: {
    ...mapGetters([
      'hotelId',
      'order/orderSerial',
      'isTouchDevice'
    ])
  },
  activated: function () {
    this.checkPaymentStatus()
  },
  methods: {
    ...mapActions([
      'order/checkQRcodePaymentStatus'
    ]),
    checkPaymentStatus: function () {
      /* eslint-disable */
      const checkInterval = setInterval(() => {
        this['order/checkQRcodePaymentStatus']()
          .then(res => {
            if (res.data.status === 0) {
              clearInterval(checkInterval)
              this.$router.push({
                name: 'Complete',
                params: {
                  hotelId: this.hotelId,
                  orderSerial: this['order/orderSerial']
                }
              })
            }
          }).catch(err => console.error('checkPaymentStatus', err))
      }, 2000)

      this.$once('hook:deactivated', () => {
        clearInterval(checkInterval)
      })
      /* eslint-enable */
    },
    goHome: function () {
      this.$router.push({
        name: 'Hotel',
        params: {
          hotelId: this.hotelId
        }
      })
    }
  }
}
</script>

<style lang='sass' scoped>
.payment-callback__qrcode-template
  margin-bottom: 150px

  .qr-code
    width: 200px
    height: 200px
    // @apply border-1 border-grey

  .btn-line
    background-color: #00c43e
</style>
