var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-callback__qrcode-template" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("blockCard", [
          _c(
            "h2",
            {
              staticClass:
                "text-orange mb-16 font-medium text-center sm:text-left"
            },
            [_vm._v(_vm._s(_vm.$t("orderNotCompleted")))]
          ),
          _c("div", { staticClass: "notice flex items-center mb-24" }, [
            _c("i", {
              staticClass:
                "owl-status-triangle-warning text-orange text-xl mr-8"
            }),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("qrCodeTip")) +
                  "\n          " +
                  _vm._s(_vm.$t(_vm.qrcodeFor)) +
                  "\n          " +
                  _vm._s(_vm.$t("continueToPay"))
              )
            ])
          ]),
          _c(
            "a",
            {
              staticClass: "block qr-code m-auto sm:ml-0",
              attrs: { href: _vm.isTouchDevice ? _vm.qrcodeUrl : "#" }
            },
            [
              _c("img", {
                staticClass: "w-full",
                attrs: { src: _vm.qrcodeImg, alt: "" }
              })
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "btn-group flex flex-col sm:block mt-16 sm:mt-40" },
          [
            _vm.isTouchDevice
              ? _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-solid btn-line w-full mb-16 sm:hidden text-center no-underline",
                    attrs: { href: _vm.qrcodeUrl }
                  },
                  [_vm._v(_vm._s(_vm.$t(_vm.qrcodeFor)))]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-solid btn-blue w-full sm:w-auto",
                on: { click: _vm.goHome }
              },
              [_vm._v(_vm._s(_vm.$t("backHome")))]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }