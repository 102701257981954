<template>
  <div class="lg:border border-grey-lighter rounded lg:mb-24">
    <div
      v-if="!collapse"
      :class='noPadding ? "" : "py-24 md:p-24"'
      class='block-card lg:mb-24 border-b-1 lg:border-0 border-grey-lighter rounded-sm'>
      <h4
        v-if='title'
        class='mb-24 font-medium'>{{ title }}</h4>
      <slot></slot>
    </div>
    <div v-if="collapse" class="my-16 px-24 collapseBlock" :class="{'open': !isMask}">
      <h4 v-if='title' class="mb-24 font-medium" @click="isMask = !isMask"><i class="owl-caret-right"></i>{{ title }}</h4>
      <div class="collapseBlock__body">
        <p class="text-sm text-grey-darker whitespace-pre-wrap"><slot></slot></p>
        <div v-show="isMask" class="collapseBlock__body__mask" @click="isMask = false"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block-card',
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMask: false
    }
  }
}
</script>

<style lang='sass' scoped>
.collapseBlock
  h4
    @apply cursor-pointer
  &.open
    .collapseBlock__body
      max-height: 100%
    .owl-caret-right
      @apply inline-block
      transform: rotate(90deg)
  &__body
    max-height: 8rem
    @apply overflow-hidden relative
    &__mask
      @apply block w-full absolute cursor-pointer
      bottom: 0
      content: ''
      height: 8rem
      background: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%)
</style>
